import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { 
          tableHead, 
          tableData, 
          tableHeaderColor, 
          enableCheckBoxButton,
          handleCheckBoxClick,
          selectedCheckBoxItems,
          selectDeselectAll,
          loadingInProgress,
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Spinner = () => {
    return (
      <Grid container justifyContent = "center">
        <CircularProgress />
      </Grid>
    );
  }

  const TableWithCheckBox = () => {
    return (
      <div className={classes.tableResponsive}>
          <TableContainer className={classes.container}>
              <Table className={classes.table}>          
                    {tableHead !== undefined ? (
                      <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                          {enableCheckBoxButton &&
                              <TableCell className={classes.tableCell} scope="row">
                                <Checkbox
                                    onChange={selectDeselectAll}
                                    checked={selectedCheckBoxItems.length === tableData.length}
                                />
                              </TableCell>
                          }
                          {tableHead.map((prop, key) => {
                            return (
                              <TableCell
                                className={classes.tableCell + " " + classes.tableHeadCell}
                                key={key}
                              >
                                {prop}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                    ) : null}
                    <TableBody>
                    {tableData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((prop, key) => {
                            return (
                              <TableRow key={key} className={classes.tableBodyRow}>
                                {enableCheckBoxButton &&
                                  <TableCell className={classes.tableCell} scope="row">
                                    <Checkbox
                                        value={prop[0]}
                                        onChange={handleCheckBoxClick}
                                        checked={selectedCheckBoxItems.includes(prop[0])}
                                    />
                                  </TableCell>
                                }
                                {prop.map((tableCellData, index) => {
                                  return (
                                    <TableCell className={classes.tableCell} key={index}>
                                      {tableCellData}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                      })}
                    </TableBody>
            
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </div>
    );
  }

  return (
    <div>
       {loadingInProgress ? <Spinner /> : <TableWithCheckBox />}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
};