import React, {useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import HrDataSource from "assets/data/HR.json";

/**
 * Get Request
 * @param {string} url 
 * @returns {Object} responseJson
 */
async function getData(url) {
  const response = await fetch(url);
  return response.json();
}

/**
 * Get Department data from the HR.json file.
 * @param {Array} dataSource 
 * @returns {Array} departmentData
 */
function getDepartmentData(dataSource){
  let deptData = []
  dataSource.map((prop) => {
    prop.manager = prop.manager.name.first  + " " + prop.manager.name.last
    deptData.push([prop.department, prop.location, prop.manager]);
  })
  return deptData;
}

/**
 * Get Employee data using department names as an input we then request randomuser.me  
 * Rest API endpoint to get the employee data. We process and filter 
 * the employee data and return it.
 * @param {Array} departmentNames 
 * @returns {Array} employeeData
 */
async function getEmployeeData(departmentNames) {
    let data = [];
    for (const departmentName of departmentNames) { 
          const response = await getData(`https://randomuser.me/api/?seed=${departmentName}&results=10`);
          const results = response.results;
          results.map((prop, index) => {
                const name = `${prop.name.title} ${prop.name.first} ${prop.name.last}`
                const gender = prop.gender
                const email = prop.email
                const location = `${prop.location.city}, ${prop.location.state}, ${prop.location.country}`
                const phone = prop.phone
                data.push([departmentName, name, gender, email, location, phone])
          })
    }
    return data;
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
const useStyles = makeStyles(styles);
const departmentData = getDepartmentData(HrDataSource.departments);

export default function TableList() {
  const classes = useStyles();

  // If the local storage selectedCheckBoxItems key is available
  // Assign  selectedCheckBoxItems local storage value to the React state
  // Else assign the default value that is an empty array to the React state.
  const [selectedCheckBoxItems, setSelectedCheckBoxItems] = React.useState(() => {
    const localData  = localStorage.getItem("selectedCheckBoxItems");
    return localData ? JSON.parse(localData) : []
  });

  // If the local storage employeeData key is available
  // Assign  employeeData local storage value to the React state
  // Else assign the default value that is an empty array to the React state.
  const [employeeData, setEmployeeData] = React.useState(() => {
    const localData  = localStorage.getItem("employeeData");
    return localData ? JSON.parse(localData) : []
  });

  const [isEmployeeDataLoadingInProgress, setIsEmployeeDataLoadingInProgress] = React.useState(false);

  /**
   * If clicked checkBoxItem is not present in the selectedCheckBoxItems react array state
   *    Push the selected item to the selectedCheckBoxItems react array state
   * Else
   *    Remove the selected item from the selectedCheckBoxItems react array state
   * Generate employee data using selectedItems as an input and 
   * set it to employeeData react state.
   * @param {Object} event 
   */
  const handleCheckBoxClick = async (event) => {
    let selectedItems = [...selectedCheckBoxItems];
    let selectedItem = event.target.value;
    setIsEmployeeDataLoadingInProgress(true);
    if(selectedItems.includes(selectedItem)){
        selectedItems = selectedItems.filter(item => item !== selectedItem);
    }else{
        selectedItems.push(selectedItem);
    }
    setSelectedCheckBoxItems(selectedItems);
    setEmployeeData(await getEmployeeData(selectedItems));
    setIsEmployeeDataLoadingInProgress(false);
  };

  /**
   * Select/Deselect All checkbox functionality
   * @param {Object} event 
   */
  const selectDeselectAll = async (event) => {
    let selectedItems = [];
    setIsEmployeeDataLoadingInProgress(true);
    if(selectedCheckBoxItems.length !== departmentData.length){
      departmentData.map((prop) => {
        selectedItems.push(prop[0]);
      })
    }
    setSelectedCheckBoxItems(selectedItems);
    setEmployeeData(await getEmployeeData(selectedItems));
    setIsEmployeeDataLoadingInProgress(false);
  };

  /**
   * Here using the useEffect Hook, we tell React that our component needs to store state data to 
   * local storage after render.
   * Here we are storing selectedCheckBoxItems, employeeData react state to the local storage.
   * So they are persistent even after a page is refreshed.
   */
  useEffect(() => {
      localStorage.setItem("selectedCheckBoxItems", JSON.stringify(selectedCheckBoxItems))
      localStorage.setItem("employeeData", JSON.stringify(employeeData))
    },
    [ 
      selectedCheckBoxItems, 
      employeeData 
    ]
  )

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Departments</h4> 
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Department", "Location", "Manager"]}
              tableData={departmentData}
              enableCheckBoxButton={true}
              handleCheckBoxClick={handleCheckBoxClick}
              selectedCheckBoxItems={selectedCheckBoxItems}
              selectDeselectAll={selectDeselectAll}
              loadingInProgress={false}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Employees</h4> 
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Department", "Name", "Gender", "Email", "Location", "Phone Number"]}
              tableData={employeeData}
              enableCheckBoxButton={false}
              loadingInProgress={isEmployeeDataLoadingInProgress}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}